<template>
	<div class="relative">
		<div ref="triggerRef" @click="togglePopover">
			<slot name="button"></slot>
		</div>

		<Teleport to="body">
			<Transition
				enter-active-class="transition ease-out duration-200"
				enter-from-class="opacity-0 translate-y-1"
				enter-to-class="opacity-100 translate-y-0"
				leave-active-class="transition ease-in duration-150"
				leave-from-class="opacity-100 translate-y-0"
				leave-to-class="opacity-0 translate-y-1"
			>
				<div
					v-if="model"
					ref="popoverContent"
					:class="`ax-popover absolute z-40 mt-2 w-72 min-w-[14rem] focus:outline-none lg:w-max lg:max-w-sm ${popoverClass}`"
					:style="popoverStyle"
				>
					<slot></slot>
				</div>
			</Transition>
		</Teleport>
	</div>
</template>

<script setup lang="ts">
import {
	onClickOutside,
	useResizeObserver,
	useMutationObserver,
} from '@vueuse/core';

const model = defineModel<boolean>();

const props = withDefaults(
	defineProps<{
		origin?: 'left' | 'right';
		popoverClass?: string;
	}>(),
	{
		origin: 'left',
	}
);

const triggerRef = ref<HTMLElement | null>(null);
const popoverContent = ref<HTMLElement | null>(null);

const togglePopover = () => (model.value = !model.value);

onClickOutside(popoverContent, (event) => {
	if (model.value && !triggerRef.value?.contains(event.target as Node)) {
		model.value = false;
	}
});

const calculatePopoverStyle = () => {
	if (!triggerRef.value) return {};
	const rect = triggerRef.value.getBoundingClientRect();
	const scrollbarWidth =
		window.innerWidth - document.documentElement.clientWidth;
	return {
		top: `${rect.bottom + window.scrollY}px`,
		[props.origin]: `${
			props.origin === 'left'
				? rect.left
				: window.innerWidth - rect.right - scrollbarWidth
		}px`,
		transform: 'translateZ(0)',
	};
};

const popoverStyle = ref(calculatePopoverStyle());

const updatePopoverPosition = useDebounceFn(() => {
	if (model.value) {
		requestAnimationFrame(() => {
			popoverStyle.value = calculatePopoverStyle();
		});
	}
}, 32);

useResizeObserver(triggerRef, updatePopoverPosition);

useMutationObserver(triggerRef, updatePopoverPosition, {
	attributes: true,
	childList: true,
	subtree: true,
	characterData: true,
});

onMounted(() => {
	if (triggerRef.value) {
		let parent = triggerRef.value.parentElement;
		while (parent) {
			useMutationObserver(parent, updatePopoverPosition, {
				attributes: true,
				childList: true,
				subtree: false,
				characterData: true,
			});
			parent = parent.parentElement;
		}
	}
});

useEventListener(window, 'resize', updatePopoverPosition, { passive: true });
useEventListener(document, 'scroll', updatePopoverPosition, {
	passive: true,
	capture: true,
});

watch(
	() => model.value,
	(newValue) => {
		if (newValue) {
			nextTick(() => {
				popoverStyle.value = calculatePopoverStyle();

				setTimeout(updatePopoverPosition, 50);
			});
		}
	}
);

defineExpose({
	openPopover: () => emit('update:modelValue', true),
	closePopover: () => emit('update:modelValue', false),
});
</script>
