import {
	ColumnDef,
	ColumnOrderState,
	ColumnSort,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	PaginationState,
	RowData,
	SortingState,
	Table,
	useVueTable,
	VisibilityState,
} from '@tanstack/vue-table';
import { RouteLocationRaw } from 'vue-router';
import {
	ColumnPropertiesState,
	FilterObject,
	PowerTableStateV3,
	TableFiltersState,
	TablePropertiesState,
} from './usePowerTableFeatures';

export const POWERTABLE_SCHEMA_VERSION = 1;

export interface PowerTableInitialState {
	columnVisibility?: VisibilityState;
	columnOrder?: ColumnOrderState;
	sorting?: SortingState;
}

export type PowerTable<TData extends RowData> = Table<TData>;
export type PowerTablePagination = PaginationState | null | undefined;
export type PowerTableSorting = ColumnSort | null | undefined;
export type PowerTableFiltering<TFilters extends FilterObject> =
	| TableFiltersState<TFilters>
	| null
	| undefined;

export interface PowerGridOptions<
	TData,
	TFilters extends FilterObject = FilterObject
> {
	// Definition
	columns: ColumnDef<TData, any>[];
	filters?: TableFilter<TFilters>[];

	// Data
	data: Ref<any[]> | Ref<undefined> | undefined;
	rowCount?:
		| MaybeRef<number | undefined | null>
		| ComputedRef<number | undefined | null>;

	// Options
	initialState?: Partial<PowerTableStateV3<TData>>;
	rowLink?: (row: TData) => RouteLocationRaw;
	paginationQueryParams?: boolean;
	disablePagination?: boolean;

	// Manual states (used for server-side operations)
	manualPaginationState?: Ref<PowerTablePagination>;
	manualSortingState?: Ref<PowerTableSorting>;
	manualTableFiltersState?: Ref<PowerTableFiltering<any>>;
	manualTableFilterValues?: Ref<TFilters | undefined | null>;
}

export default function <
	TData extends RowData,
	TFilters extends FilterObject = FilterObject
>(initialOptions: PowerGridOptions<TData, TFilters>) {
	// Make sure that no columns has same ID
	const columnIds = initialOptions.columns.map((column) => column.id);
	const uniqueColumnIds = new Set(columnIds);
	if (columnIds.length !== uniqueColumnIds.size) {
		throw new Error('PowerTable: Duplicate column IDs are not allowed.');
	}

	// Custom features
	const { tableFeature } = usePowerTableFeatures<TData>();

	const defaultOptions: Partial<PowerGridOptions<TData, TFilters>> = {
		paginationQueryParams: true,
	};

	const options = {
		...defaultOptions,
		...initialOptions,
	};

	const {
		getInitialPaginationState,
		getInitialSortingState,
		getInitialColumnPropertiesState,
		getInitialColumnVisibilityState,
		getInitialColumnOrderState,
		getInitialTablePropertiesState,
		getInitialTableFiltersState,
		getInitialTableFilterVisibilityState,
		getInitialTableFilterValueState,
	} = usePowerTableUtils(options);

	// INTERNAL STATES
	const internalPaginationState = ref<PaginationState>(
		getInitialPaginationState()
	);
	if (options?.manualPaginationState) {
		options.manualPaginationState.value = internalPaginationState.value;
	}

	const internalSortingState = ref<SortingState>(getInitialSortingState());
	if (options?.manualSortingState) {
		options.manualSortingState.value = internalSortingState.value?.[0];
	}

	const internalColumnVisibilityState = ref<VisibilityState>(
		getInitialColumnVisibilityState()
	);

	const internalColumnPropertiesState = ref<ColumnPropertiesState>(
		getInitialColumnPropertiesState()
	);

	const internalColumnOrderState = ref<ColumnOrderState>(
		getInitialColumnOrderState()
	);
	const internalTablePropertiesState = ref<TablePropertiesState<TData>>(
		getInitialTablePropertiesState()
	);
	const internalTableFiltersState = ref<TableFiltersState>(
		getInitialTableFiltersState()
	);
	if (options?.manualTableFiltersState) {
		options.manualTableFiltersState.value = internalTableFiltersState.value;
	}

	const internalTableFilterVisibilityState = ref<TableFilterVisibilityState>(
		getInitialTableFilterVisibilityState()
	);

	const internalTableFilterValueState = ref<TableFilterValueState>(
		getInitialTableFilterValueState()
	);
	if (options?.manualTableFilterValues) {
		options.manualTableFilterValues.value =
			internalTableFilterValueState.value as TFilters;
	}

	// ON LOAD LOGIC
	const route = useRoute();
	if (route.query?.page) {
		const page = Number(route.query.page);
		if (page >= 0) {
			internalPaginationState.value = {
				...internalPaginationState.value,
				pageIndex: page,
			};

			if (options?.manualPaginationState) {
				options.manualPaginationState.value =
					internalPaginationState.value;
			}
		}
	}

	const table = useVueTable({
		data: computed(() => options.data?.value ?? []),
		_features: [tableFeature],
		columns: options.columns,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		manualPagination:
			!!options?.manualPaginationState || options?.disablePagination,
		get rowCount() {
			if (options?.disablePagination) {
				return 0;
			}

			if (options.manualPaginationState) {
				if (!options?.rowCount) {
					return 0;
				}

				const rowCount = unref(options!.rowCount);
				return rowCount ?? 0;
			}

			return options.data?.value?.length ?? 0;
		},

		rowLink: options?.rowLink ? options.rowLink : (row) => undefined,

		onPaginationChange: (updater) => {
			internalPaginationState.value =
				updater instanceof Function
					? updater(internalPaginationState.value)
					: updater;

			if (options?.manualPaginationState) {
				options.manualPaginationState.value =
					internalPaginationState.value;
			}

			// Set pagination query params
			if (options.paginationQueryParams) {
				if (internalPaginationState.value.pageIndex === 0) {
					navigateTo({
						query: {
							page: undefined,
						},
					});
				} else {
					navigateTo({
						query: {
							page: internalPaginationState.value.pageIndex,
						},
					});
				}
			}
		},

		onColumnVisibilityChange: (updater) => {
			internalColumnVisibilityState.value =
				updater instanceof Function
					? updater(internalColumnVisibilityState.value)
					: updater;
		},

		onColumnPropertiesChange: (updater) => {
			internalColumnPropertiesState.value =
				updater instanceof Function
					? updater(internalColumnPropertiesState.value)
					: updater;
		},

		onColumnOrderChange: (updater) => {
			internalColumnOrderState.value =
				updater instanceof Function
					? updater(internalColumnOrderState.value)
					: updater;
		},

		onTablePropertiesChange: (updater) => {
			internalTablePropertiesState.value =
				updater instanceof Function
					? updater(internalTablePropertiesState.value)
					: updater;
		},

		onTableFiltersChange: (updater) => {
			internalTableFiltersState.value =
				updater instanceof Function
					? updater(internalTableFiltersState.value)
					: updater;

			if (options?.manualTableFiltersState) {
				options.manualTableFiltersState.value =
					internalTableFiltersState.value;
			}
		},

		onTableFilterValueChange: (updater) => {
			internalTableFilterValueState.value =
				updater instanceof Function
					? updater(internalTableFilterValueState.value)
					: updater;

			if (options?.manualTableFilterValues) {
				options.manualTableFilterValues.value =
					internalTableFilterValueState.value as TFilters;
			}
		},

		onTableFilterVisibilityChange: (updater) => {
			internalTableFilterVisibilityState.value =
				updater instanceof Function
					? updater(internalTableFilterVisibilityState.value)
					: updater;
		},

		// Sorting
		enableMultiSort: false,
		manualSorting: !!options?.manualSortingState,
		sortDescFirst: false,
		onSortingChange: (updater) => {
			internalSortingState.value =
				updater instanceof Function
					? updater(internalSortingState.value)
					: updater;

			if (options?.manualSortingState) {
				options.manualSortingState.value =
					internalSortingState.value?.[0] ?? null;
			}
		},

		state: {
			get pagination() {
				if (options?.disablePagination) {
					return { pageIndex: 0, pageSize: 0 };
				}

				return internalPaginationState.value;
			},
			get sorting() {
				return internalSortingState.value;
			},
			get columnProperties() {
				return internalColumnPropertiesState.value;
			},
			get columnVisibility() {
				return internalColumnVisibilityState.value;
			},
			get columnOrder() {
				return internalColumnOrderState.value;
			},
			get tableProperties() {
				return internalTablePropertiesState.value;
			},
			get tableFilters() {
				return internalTableFiltersState.value;
			},
			get tableFilterVisibility() {
				return internalTableFilterVisibilityState.value;
			},
			get tableFilterValue() {
				return internalTableFilterValueState.value;
			},
		},
	});

	return {
		paginationState: internalPaginationState,
		table,
	};
}
