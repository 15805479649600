import { default as historyk4WYWo2E8HMeta } from "/app/pages/account-meetings/[accountMeetingId]/history.vue?macro=true";
import { default as indexaDXiR7HyxKMeta } from "/app/pages/account-meetings/[accountMeetingId]/index.vue?macro=true";
import { default as _91accountMeetingId_93046Nqll762Meta } from "/app/pages/account-meetings/[accountMeetingId].vue?macro=true";
import { default as indexNkcf47HgCHMeta } from "/app/pages/account-meetings/index.vue?macro=true";
import { default as _91_91powerGridId_93_93K0vuQS4jrrMeta } from "/app/pages/account-meetings/list/[[powerGridId]].vue?macro=true";
import { default as newHbXwQAYsuDMeta } from "/app/pages/account-meetings/new.vue?macro=true";
import { default as addressescTqeOEKm7yMeta } from "/app/pages/accounts/[accountId]/addresses.vue?macro=true";
import { default as contactsGlulN5xmKWMeta } from "/app/pages/accounts/[accountId]/contacts.vue?macro=true";
import { default as detailswwWYJN7CFyMeta } from "/app/pages/accounts/[accountId]/details.vue?macro=true";
import { default as history9mfMYrqcnqMeta } from "/app/pages/accounts/[accountId]/history.vue?macro=true";
import { default as meetingsIwpg6jaT9CMeta } from "/app/pages/accounts/[accountId]/meetings.vue?macro=true";
import { default as _91accountId_93IFmCMPl9aJMeta } from "/app/pages/accounts/[accountId].vue?macro=true";
import { default as indexRPbMK1uYtmMeta } from "/app/pages/accounts/index.vue?macro=true";
import { default as _91_91powerGridId_93_93Nl2HJDn1D0Meta } from "/app/pages/accounts/list/[[powerGridId]].vue?macro=true";
import { default as new1086Z7sn7eMeta } from "/app/pages/accounts/new.vue?macro=true";
import { default as entitiesQaFd7kNqaXMeta } from "/app/pages/admin/developer/entities.vue?macro=true";
import { default as grids7kcHjsSPP1Meta } from "/app/pages/admin/developer/grids.vue?macro=true";
import { default as languagesZKpTis3vNFMeta } from "/app/pages/admin/developer/languages.vue?macro=true";
import { default as master_45modulesiNh70rIjrDMeta } from "/app/pages/admin/developer/master-modules.vue?macro=true";
import { default as provisioningVvUlHxy7oEMeta } from "/app/pages/admin/developer/provisioning.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as modulesGybAKqUaAUMeta } from "/app/pages/admin/modules.vue?macro=true";
import { default as templatesjrtR3bL67ZMeta } from "/app/pages/admin/templates.vue?macro=true";
import { default as test9klnpvgJQeMeta } from "/app/pages/admin/test.vue?macro=true";
import { default as admin0eiku7Jj1aMeta } from "/app/pages/admin.vue?macro=true";
import { default as indexEJicMmbXUWMeta } from "/app/pages/allergies/index.vue?macro=true";
import { default as _91gridId_93SiLYM66OJqMeta } from "/app/pages/allergies/list/[gridId].vue?macro=true";
import { default as client1rd7HLbgcBMeta } from "/app/pages/auth/client.vue?macro=true";
import { default as cm_45settingsPYElCdYn4wMeta } from "/app/pages/cm-settings.vue?macro=true";
import { default as detailsOdTZPfKeYgMeta } from "/app/pages/contacts/[contactId]/details.vue?macro=true";
import { default as historyfAdLAF5KwcMeta } from "/app/pages/contacts/[contactId]/history.vue?macro=true";
import { default as meetingsstpuj4AdM7Meta } from "/app/pages/contacts/[contactId]/meetings.vue?macro=true";
import { default as social_45handleslPag9Xl4UmMeta } from "/app/pages/contacts/[contactId]/social-handles.vue?macro=true";
import { default as _91contactId_93Miu2qCkL8rMeta } from "/app/pages/contacts/[contactId].vue?macro=true";
import { default as indexQ6aaLm6uHyMeta } from "/app/pages/contacts/index.vue?macro=true";
import { default as _91_91powerGridId_93_934R5PvNR1exMeta } from "/app/pages/contacts/list/[[powerGridId]].vue?macro=true";
import { default as newnzc53K5xcAMeta } from "/app/pages/contacts/new.vue?macro=true";
import { default as indextaC1gSU56TMeta } from "/app/pages/crm/index.vue?macro=true";
import { default as custom_45fieldsAth4sN4nlFMeta } from "/app/pages/crm/settings/accounts/custom-fields.vue?macro=true";
import { default as campaign_45monitorGLenR74EgcMeta } from "/app/pages/crm/settings/campaign-monitor.vue?macro=true";
import { default as custom_45fields3RXojxLhfCMeta } from "/app/pages/crm/settings/contacts/custom-fields.vue?macro=true";
import { default as positions5uF33E9bT2Meta } from "/app/pages/crm/settings/contacts/positions.vue?macro=true";
import { default as settings1vPLoxrOpVMeta } from "/app/pages/crm/settings.vue?macro=true";
import { default as absenceuUlnKAf5YKMeta } from "/app/pages/employees/[id]/absence.vue?macro=true";
import { default as addressesCOpsD9Y3xbMeta } from "/app/pages/employees/[id]/addresses.vue?macro=true";
import { default as allergiessqwFZwe0YTMeta } from "/app/pages/employees/[id]/allergies.vue?macro=true";
import { default as benefitsm3sa72AbaxMeta } from "/app/pages/employees/[id]/benefits.vue?macro=true";
import { default as certificatesF5ntoIhZAwMeta } from "/app/pages/employees/[id]/certificates.vue?macro=true";
import { default as competences5UD42EBNixMeta } from "/app/pages/employees/[id]/competences.vue?macro=true";
import { default as detailswzxhbbEsnRMeta } from "/app/pages/employees/[id]/details.vue?macro=true";
import { default as devicesuOzBqwjRgKMeta } from "/app/pages/employees/[id]/devices.vue?macro=true";
import { default as educationsubhXcPVtvXMeta } from "/app/pages/employees/[id]/educations.vue?macro=true";
import { default as employmentsGrcAImItTAMeta } from "/app/pages/employees/[id]/employments.vue?macro=true";
import { default as familyb6H5Z9mhgjMeta } from "/app/pages/employees/[id]/family.vue?macro=true";
import { default as historywJBArPHd0cMeta } from "/app/pages/employees/[id]/history.vue?macro=true";
import { default as insurances2qoSYn1ebQMeta } from "/app/pages/employees/[id]/insurances.vue?macro=true";
import { default as _91meetingId_93WScZ4Un4kXMeta } from "/app/pages/employees/[id]/meetings/[meetingId].vue?macro=true";
import { default as indexVPRdh4p4HVMeta } from "/app/pages/employees/[id]/meetings/index.vue?macro=true";
import { default as officesgNwhCaKT6BMeta } from "/app/pages/employees/[id]/offices.vue?macro=true";
import { default as okr_45objectivesZdbQg48MxJMeta } from "/app/pages/employees/[id]/okr-objectives.vue?macro=true";
import { default as teamsVMMpCKobxBMeta } from "/app/pages/employees/[id]/teams.vue?macro=true";
import { default as _91id_93x9zTtskU8RMeta } from "/app/pages/employees/[id].vue?macro=true";
import { default as approval6xgvNrmuGlMeta } from "/app/pages/employees/absence/approval.vue?macro=true";
import { default as balances2JYTOJK9WbMeta } from "/app/pages/employees/absence/balances.vue?macro=true";
import { default as calendarwxERFbgLwfMeta } from "/app/pages/employees/absence/calendar.vue?macro=true";
import { default as indexkkok1ZfstGMeta } from "/app/pages/employees/index.vue?macro=true";
import { default as _91_91powerGridId_93_93ZhgDbjgh7cMeta } from "/app/pages/employees/list/[[powerGridId]].vue?macro=true";
import { default as newfFiyG5BjUsMeta } from "/app/pages/employees/new.vue?macro=true";
import { default as error_45test3kxMEJ7wzOMeta } from "/app/pages/error-test.vue?macro=true";
import { default as indexlZMmravdr2Meta } from "/app/pages/hr/index.vue?macro=true";
import { default as certificate_45types4WGNKKFpuBMeta } from "/app/pages/hr/settings/employees/certificate-types.vue?macro=true";
import { default as competence_45typeszYXxxRuGIzMeta } from "/app/pages/hr/settings/employees/competence-types.vue?macro=true";
import { default as custom_45fieldsvFquGxuHJeMeta } from "/app/pages/hr/settings/employees/custom-fields.vue?macro=true";
import { default as positionsIQVBXbHpygMeta } from "/app/pages/hr/settings/employees/positions.vue?macro=true";
import { default as settingsiRWVSivdRgMeta } from "/app/pages/hr/settings.vue?macro=true";
import { default as _91id_93P1tkOVk6sGMeta } from "/app/pages/ideas/[id].vue?macro=true";
import { default as indexQ0fd8Nluc5Meta } from "/app/pages/ideas/index.vue?macro=true";
import { default as _91_91powerGridId_93_93f4oxeaqnqxMeta } from "/app/pages/ideas/list/[[powerGridId]].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as meQzFFSRzs0uMeta } from "/app/pages/me.vue?macro=true";
import { default as no_45clients_45availablet7A7X24UYWMeta } from "/app/pages/no-clients-available.vue?macro=true";
import { default as order_45module4gxpwsWkKlMeta } from "/app/pages/order-module.vue?macro=true";
import { default as addressesnqrlJtkJphMeta } from "/app/pages/organisations/[id]/addresses.vue?macro=true";
import { default as benefits9WDzwyrq17Meta } from "/app/pages/organisations/[id]/benefits.vue?macro=true";
import { default as competences4OMcbGB8gQMeta } from "/app/pages/organisations/[id]/competences.vue?macro=true";
import { default as departmentseBbUrQjqHTMeta } from "/app/pages/organisations/[id]/departments.vue?macro=true";
import { default as editjBCzC9hR0pMeta } from "/app/pages/organisations/[id]/edit.vue?macro=true";
import { default as employeeszO3mQPuOnKMeta } from "/app/pages/organisations/[id]/employees.vue?macro=true";
import { default as insurancesPQq1223PMDMeta } from "/app/pages/organisations/[id]/insurances.vue?macro=true";
import { default as numberingL6HfztPG0EMeta } from "/app/pages/organisations/[id]/numbering.vue?macro=true";
import { default as officesgW97QQTQpjMeta } from "/app/pages/organisations/[id]/offices.vue?macro=true";
import { default as responsibilities9YnD1k7Fo1Meta } from "/app/pages/organisations/[id]/responsibilities.vue?macro=true";
import { default as teamsGGV1Bs2jMpMeta } from "/app/pages/organisations/[id]/teams.vue?macro=true";
import { default as _91id_93a3DFfTAo7xMeta } from "/app/pages/organisations/[id].vue?macro=true";
import { default as indexrsRd7g7xvNMeta } from "/app/pages/organisations/index.vue?macro=true";
import { default as _91gridId_93SEns5WsAWQMeta } from "/app/pages/organisations/list/[gridId].vue?macro=true";
import { default as newYZcB0p55B2Meta } from "/app/pages/organisations/new.vue?macro=true";
import { default as powergrid_45v3bxRJbghqWnMeta } from "/app/pages/powergrid-v3.vue?macro=true";
import { default as indexlYxgOizsZ0Meta } from "/app/pages/projects/[id]/index.vue?macro=true";
import { default as rich_45text_45demol7uLAGIyWnMeta } from "/app/pages/rich-text-demo.vue?macro=true";
import { default as server_45errorNGtcXOgpJYMeta } from "/app/pages/server-error.vue?macro=true";
import { default as clientd1Fzkzv1cFMeta } from "/app/pages/setup/client.vue?macro=true";
import { default as forgotten_45password2LboLVWLh9Meta } from "/app/pages/styleguide/forgotten-password.vue?macro=true";
import { default as formkitdemoFQvHntyOa9Meta } from "/app/pages/styleguide/formkitdemo.vue?macro=true";
import { default as indexWz9Rc7A7o7Meta } from "/app/pages/styleguide/index.vue?macro=true";
import { default as loginqAmahHOZNGMeta } from "/app/pages/styleguide/login.vue?macro=true";
import { default as organizationNewgDXtTAqNaEMeta } from "/app/pages/styleguide/organizationNew.vue?macro=true";
import { default as sidebar_45dialogq8xBXvLElqMeta } from "/app/pages/styleguide/sidebar-dialog.vue?macro=true";
import { default as editx7A3tlqm0GMeta } from "/app/pages/templates/[id]/edit.vue?macro=true";
import { default as historyNT11UNLFgNMeta } from "/app/pages/templates/[id]/history.vue?macro=true";
import { default as _91id_93MIPvrfU6G4Meta } from "/app/pages/templates/[id].vue?macro=true";
import { default as index6xet4YTGVdMeta } from "/app/pages/templates/index.vue?macro=true";
import { default as newu8lSFnZjanMeta } from "/app/pages/templates/new.vue?macro=true";
import { default as workflow_45pocgXd5E72vgWMeta } from "/app/pages/workflow-poc.vue?macro=true";
export default [
  {
    name: _91accountMeetingId_93046Nqll762Meta?.name,
    path: "/account-meetings/:accountMeetingId()",
    component: () => import("/app/pages/account-meetings/[accountMeetingId].vue").then(m => m.default || m),
    children: [
  {
    name: "account-meetings-accountMeetingId-history",
    path: "history",
    meta: historyk4WYWo2E8HMeta || {},
    component: () => import("/app/pages/account-meetings/[accountMeetingId]/history.vue").then(m => m.default || m)
  },
  {
    name: "account-meetings-accountMeetingId",
    path: "",
    meta: indexaDXiR7HyxKMeta || {},
    component: () => import("/app/pages/account-meetings/[accountMeetingId]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "account-meetings",
    path: "/account-meetings",
    component: () => import("/app/pages/account-meetings/index.vue").then(m => m.default || m)
  },
  {
    name: "account-meetings-list-powerGridId",
    path: "/account-meetings/list/:powerGridId?",
    component: () => import("/app/pages/account-meetings/list/[[powerGridId]].vue").then(m => m.default || m)
  },
  {
    name: "account-meetings-new",
    path: "/account-meetings/new",
    component: () => import("/app/pages/account-meetings/new.vue").then(m => m.default || m)
  },
  {
    name: "accounts-accountId",
    path: "/accounts/:accountId()",
    component: () => import("/app/pages/accounts/[accountId].vue").then(m => m.default || m),
    children: [
  {
    name: "accounts-accountId-addresses",
    path: "addresses",
    component: () => import("/app/pages/accounts/[accountId]/addresses.vue").then(m => m.default || m)
  },
  {
    name: "accounts-accountId-contacts",
    path: "contacts",
    component: () => import("/app/pages/accounts/[accountId]/contacts.vue").then(m => m.default || m)
  },
  {
    name: "accounts-accountId-details",
    path: "details",
    meta: detailswwWYJN7CFyMeta || {},
    alias: ["/accounts/:accountId"],
    component: () => import("/app/pages/accounts/[accountId]/details.vue").then(m => m.default || m)
  },
  {
    name: "accounts-accountId-history",
    path: "history",
    meta: history9mfMYrqcnqMeta || {},
    component: () => import("/app/pages/accounts/[accountId]/history.vue").then(m => m.default || m)
  },
  {
    name: "accounts-accountId-meetings",
    path: "meetings",
    component: () => import("/app/pages/accounts/[accountId]/meetings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "accounts",
    path: "/accounts",
    component: () => import("/app/pages/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-list-powerGridId",
    path: "/accounts/list/:powerGridId?",
    component: () => import("/app/pages/accounts/list/[[powerGridId]].vue").then(m => m.default || m)
  },
  {
    name: "accounts-new",
    path: "/accounts/new",
    component: () => import("/app/pages/accounts/new.vue").then(m => m.default || m)
  },
  {
    name: "Admin",
    path: "/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-developer-entities",
    path: "developer/entities",
    meta: entitiesQaFd7kNqaXMeta || {},
    component: () => import("/app/pages/admin/developer/entities.vue").then(m => m.default || m)
  },
  {
    name: "admin-developer-grids",
    path: "developer/grids",
    meta: grids7kcHjsSPP1Meta || {},
    component: () => import("/app/pages/admin/developer/grids.vue").then(m => m.default || m)
  },
  {
    name: "admin-developer-languages",
    path: "developer/languages",
    meta: languagesZKpTis3vNFMeta || {},
    component: () => import("/app/pages/admin/developer/languages.vue").then(m => m.default || m)
  },
  {
    name: "admin-developer-master-modules",
    path: "developer/master-modules",
    meta: master_45modulesiNh70rIjrDMeta || {},
    component: () => import("/app/pages/admin/developer/master-modules.vue").then(m => m.default || m)
  },
  {
    name: "admin-developer-provisioning",
    path: "developer/provisioning",
    component: () => import("/app/pages/admin/developer/provisioning.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "",
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-modules",
    path: "modules",
    meta: modulesGybAKqUaAUMeta || {},
    component: () => import("/app/pages/admin/modules.vue").then(m => m.default || m)
  },
  {
    name: "admin-templates",
    path: "templates",
    meta: templatesjrtR3bL67ZMeta || {},
    component: () => import("/app/pages/admin/templates.vue").then(m => m.default || m)
  },
  {
    name: "admin-test",
    path: "test",
    component: () => import("/app/pages/admin/test.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "allergies",
    path: "/allergies",
    meta: indexEJicMmbXUWMeta || {},
    component: () => import("/app/pages/allergies/index.vue").then(m => m.default || m)
  },
  {
    name: "allergies-list-gridId",
    path: "/allergies/list/:gridId()",
    meta: _91gridId_93SiLYM66OJqMeta || {},
    component: () => import("/app/pages/allergies/list/[gridId].vue").then(m => m.default || m)
  },
  {
    name: "auth-client",
    path: "/auth/client",
    component: () => import("/app/pages/auth/client.vue").then(m => m.default || m)
  },
  {
    name: "cm-settings",
    path: "/cm-settings",
    component: () => import("/app/pages/cm-settings.vue").then(m => m.default || m)
  },
  {
    name: "contacts-contactId",
    path: "/contacts/:contactId()",
    component: () => import("/app/pages/contacts/[contactId].vue").then(m => m.default || m),
    children: [
  {
    name: "contacts-contactId-details",
    path: "details",
    meta: detailsOdTZPfKeYgMeta || {},
    alias: ["/contacts/:contactId"],
    component: () => import("/app/pages/contacts/[contactId]/details.vue").then(m => m.default || m)
  },
  {
    name: "contacts-contactId-history",
    path: "history",
    meta: historyfAdLAF5KwcMeta || {},
    component: () => import("/app/pages/contacts/[contactId]/history.vue").then(m => m.default || m)
  },
  {
    name: "contacts-contactId-meetings",
    path: "meetings",
    component: () => import("/app/pages/contacts/[contactId]/meetings.vue").then(m => m.default || m)
  },
  {
    name: "contacts-contactId-social-handles",
    path: "social-handles",
    component: () => import("/app/pages/contacts/[contactId]/social-handles.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/app/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "contacts-list-powerGridId",
    path: "/contacts/list/:powerGridId?",
    component: () => import("/app/pages/contacts/list/[[powerGridId]].vue").then(m => m.default || m)
  },
  {
    name: "contacts-new",
    path: "/contacts/new",
    component: () => import("/app/pages/contacts/new.vue").then(m => m.default || m)
  },
  {
    name: "crm",
    path: "/crm",
    component: () => import("/app/pages/crm/index.vue").then(m => m.default || m)
  },
  {
    name: "crm-settings",
    path: "/crm/settings",
    component: () => import("/app/pages/crm/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "crm-settings-accounts-custom-fields",
    path: "accounts/custom-fields",
    component: () => import("/app/pages/crm/settings/accounts/custom-fields.vue").then(m => m.default || m)
  },
  {
    name: "crm-settings-campaign-monitor",
    path: "campaign-monitor",
    meta: campaign_45monitorGLenR74EgcMeta || {},
    component: () => import("/app/pages/crm/settings/campaign-monitor.vue").then(m => m.default || m)
  },
  {
    name: "crm-settings-contacts-custom-fields",
    path: "contacts/custom-fields",
    component: () => import("/app/pages/crm/settings/contacts/custom-fields.vue").then(m => m.default || m)
  },
  {
    name: "crm-settings-contacts-positions",
    path: "contacts/positions",
    component: () => import("/app/pages/crm/settings/contacts/positions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "employees-id",
    path: "/employees/:id()",
    meta: _91id_93x9zTtskU8RMeta || {},
    component: () => import("/app/pages/employees/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "employees-id-absence",
    path: "absence",
    meta: absenceuUlnKAf5YKMeta || {},
    component: () => import("/app/pages/employees/[id]/absence.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-addresses",
    path: "addresses",
    meta: addressesCOpsD9Y3xbMeta || {},
    component: () => import("/app/pages/employees/[id]/addresses.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-allergies",
    path: "allergies",
    meta: allergiessqwFZwe0YTMeta || {},
    component: () => import("/app/pages/employees/[id]/allergies.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-benefits",
    path: "benefits",
    meta: benefitsm3sa72AbaxMeta || {},
    component: () => import("/app/pages/employees/[id]/benefits.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-certificates",
    path: "certificates",
    meta: certificatesF5ntoIhZAwMeta || {},
    component: () => import("/app/pages/employees/[id]/certificates.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-competences",
    path: "competences",
    meta: competences5UD42EBNixMeta || {},
    component: () => import("/app/pages/employees/[id]/competences.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-details",
    path: "details",
    meta: detailswzxhbbEsnRMeta || {},
    alias: ["/employees/:id"],
    component: () => import("/app/pages/employees/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-devices",
    path: "devices",
    meta: devicesuOzBqwjRgKMeta || {},
    component: () => import("/app/pages/employees/[id]/devices.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-educations",
    path: "educations",
    meta: educationsubhXcPVtvXMeta || {},
    component: () => import("/app/pages/employees/[id]/educations.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-employments",
    path: "employments",
    meta: employmentsGrcAImItTAMeta || {},
    component: () => import("/app/pages/employees/[id]/employments.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-family",
    path: "family",
    meta: familyb6H5Z9mhgjMeta || {},
    component: () => import("/app/pages/employees/[id]/family.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-history",
    path: "history",
    meta: historywJBArPHd0cMeta || {},
    component: () => import("/app/pages/employees/[id]/history.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-insurances",
    path: "insurances",
    meta: insurances2qoSYn1ebQMeta || {},
    component: () => import("/app/pages/employees/[id]/insurances.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-meetings-meetingId",
    path: "meetings/:meetingId()",
    meta: _91meetingId_93WScZ4Un4kXMeta || {},
    component: () => import("/app/pages/employees/[id]/meetings/[meetingId].vue").then(m => m.default || m)
  },
  {
    name: "employees-id-meetings",
    path: "meetings",
    meta: indexVPRdh4p4HVMeta || {},
    component: () => import("/app/pages/employees/[id]/meetings/index.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-offices",
    path: "offices",
    meta: officesgNwhCaKT6BMeta || {},
    component: () => import("/app/pages/employees/[id]/offices.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-okr-objectives",
    path: "okr-objectives",
    meta: okr_45objectivesZdbQg48MxJMeta || {},
    component: () => import("/app/pages/employees/[id]/okr-objectives.vue").then(m => m.default || m)
  },
  {
    name: "employees-id-teams",
    path: "teams",
    meta: teamsVMMpCKobxBMeta || {},
    component: () => import("/app/pages/employees/[id]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "employees-absence-approval",
    path: "/employees/absence/approval",
    meta: approval6xgvNrmuGlMeta || {},
    component: () => import("/app/pages/employees/absence/approval.vue").then(m => m.default || m)
  },
  {
    name: "employees-absence-balances",
    path: "/employees/absence/balances",
    meta: balances2JYTOJK9WbMeta || {},
    component: () => import("/app/pages/employees/absence/balances.vue").then(m => m.default || m)
  },
  {
    name: "employees-absence-calendar",
    path: "/employees/absence/calendar",
    component: () => import("/app/pages/employees/absence/calendar.vue").then(m => m.default || m)
  },
  {
    name: "employees",
    path: "/employees",
    component: () => import("/app/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: "employees-list-powerGridId",
    path: "/employees/list/:powerGridId?",
    component: () => import("/app/pages/employees/list/[[powerGridId]].vue").then(m => m.default || m)
  },
  {
    name: "employees-new",
    path: "/employees/new",
    meta: newfFiyG5BjUsMeta || {},
    component: () => import("/app/pages/employees/new.vue").then(m => m.default || m)
  },
  {
    name: "error-test",
    path: "/error-test",
    component: () => import("/app/pages/error-test.vue").then(m => m.default || m)
  },
  {
    name: "hr",
    path: "/hr",
    component: () => import("/app/pages/hr/index.vue").then(m => m.default || m)
  },
  {
    name: "hr-settings",
    path: "/hr/settings",
    component: () => import("/app/pages/hr/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "hr-settings-employees-certificate-types",
    path: "employees/certificate-types",
    component: () => import("/app/pages/hr/settings/employees/certificate-types.vue").then(m => m.default || m)
  },
  {
    name: "hr-settings-employees-competence-types",
    path: "employees/competence-types",
    component: () => import("/app/pages/hr/settings/employees/competence-types.vue").then(m => m.default || m)
  },
  {
    name: "hr-settings-employees-custom-fields",
    path: "employees/custom-fields",
    component: () => import("/app/pages/hr/settings/employees/custom-fields.vue").then(m => m.default || m)
  },
  {
    name: "hr-settings-employees-positions",
    path: "employees/positions",
    component: () => import("/app/pages/hr/settings/employees/positions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "ideas-id",
    path: "/ideas/:id()",
    component: () => import("/app/pages/ideas/[id].vue").then(m => m.default || m)
  },
  {
    name: "ideas",
    path: "/ideas",
    component: () => import("/app/pages/ideas/index.vue").then(m => m.default || m)
  },
  {
    name: "ideas-list-powerGridId",
    path: "/ideas/list/:powerGridId?",
    component: () => import("/app/pages/ideas/list/[[powerGridId]].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "me",
    path: "/me",
    component: () => import("/app/pages/me.vue").then(m => m.default || m)
  },
  {
    name: "no-clients-available",
    path: "/no-clients-available",
    meta: no_45clients_45availablet7A7X24UYWMeta || {},
    component: () => import("/app/pages/no-clients-available.vue").then(m => m.default || m)
  },
  {
    name: "order-module",
    path: "/order-module",
    component: () => import("/app/pages/order-module.vue").then(m => m.default || m)
  },
  {
    name: "organisations-id",
    path: "/organisations/:id()",
    meta: _91id_93a3DFfTAo7xMeta || {},
    component: () => import("/app/pages/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-addresses",
    path: "addresses",
    meta: addressesnqrlJtkJphMeta || {},
    component: () => import("/app/pages/organisations/[id]/addresses.vue").then(m => m.default || m)
  },
  {
    name: "organisations-id-benefits",
    path: "benefits",
    meta: benefits9WDzwyrq17Meta || {},
    component: () => import("/app/pages/organisations/[id]/benefits.vue").then(m => m.default || m)
  },
  {
    name: "organisations-id-competences",
    path: "competences",
    component: () => import("/app/pages/organisations/[id]/competences.vue").then(m => m.default || m)
  },
  {
    name: "organisations-id-departments",
    path: "departments",
    meta: departmentseBbUrQjqHTMeta || {},
    component: () => import("/app/pages/organisations/[id]/departments.vue").then(m => m.default || m)
  },
  {
    name: "organisations-id-edit",
    path: "edit",
    meta: editjBCzC9hR0pMeta || {},
    alias: ["/organisations/:id"],
    component: () => import("/app/pages/organisations/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "organisations-id-employees",
    path: "employees",
    meta: employeeszO3mQPuOnKMeta || {},
    component: () => import("/app/pages/organisations/[id]/employees.vue").then(m => m.default || m)
  },
  {
    name: "organisations-id-insurances",
    path: "insurances",
    meta: insurancesPQq1223PMDMeta || {},
    component: () => import("/app/pages/organisations/[id]/insurances.vue").then(m => m.default || m)
  },
  {
    name: "organisations-id-numbering",
    path: "numbering",
    meta: numberingL6HfztPG0EMeta || {},
    component: () => import("/app/pages/organisations/[id]/numbering.vue").then(m => m.default || m)
  },
  {
    name: "organisations-id-offices",
    path: "offices",
    meta: officesgW97QQTQpjMeta || {},
    component: () => import("/app/pages/organisations/[id]/offices.vue").then(m => m.default || m)
  },
  {
    name: "organisations-id-responsibilities",
    path: "responsibilities",
    meta: responsibilities9YnD1k7Fo1Meta || {},
    component: () => import("/app/pages/organisations/[id]/responsibilities.vue").then(m => m.default || m)
  },
  {
    name: "organisations-id-teams",
    path: "teams",
    meta: teamsGGV1Bs2jMpMeta || {},
    component: () => import("/app/pages/organisations/[id]/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisations",
    path: "/organisations",
    meta: indexrsRd7g7xvNMeta || {},
    component: () => import("/app/pages/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "organisations-list-gridId",
    path: "/organisations/list/:gridId()",
    component: () => import("/app/pages/organisations/list/[gridId].vue").then(m => m.default || m)
  },
  {
    name: "organisations-new",
    path: "/organisations/new",
    component: () => import("/app/pages/organisations/new.vue").then(m => m.default || m)
  },
  {
    name: "powergrid-v3",
    path: "/powergrid-v3",
    component: () => import("/app/pages/powergrid-v3.vue").then(m => m.default || m)
  },
  {
    name: "projects-id",
    path: "/projects/:id()",
    component: () => import("/app/pages/projects/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "rich-text-demo",
    path: "/rich-text-demo",
    component: () => import("/app/pages/rich-text-demo.vue").then(m => m.default || m)
  },
  {
    name: "server-error",
    path: "/server-error",
    meta: server_45errorNGtcXOgpJYMeta || {},
    component: () => import("/app/pages/server-error.vue").then(m => m.default || m)
  },
  {
    name: "setup-client",
    path: "/setup/client",
    meta: clientd1Fzkzv1cFMeta || {},
    component: () => import("/app/pages/setup/client.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-forgotten-password",
    path: "/styleguide/forgotten-password",
    meta: forgotten_45password2LboLVWLh9Meta || {},
    component: () => import("/app/pages/styleguide/forgotten-password.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-formkitdemo",
    path: "/styleguide/formkitdemo",
    component: () => import("/app/pages/styleguide/formkitdemo.vue").then(m => m.default || m)
  },
  {
    name: "styleguide",
    path: "/styleguide",
    component: () => import("/app/pages/styleguide/index.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-login",
    path: "/styleguide/login",
    meta: loginqAmahHOZNGMeta || {},
    component: () => import("/app/pages/styleguide/login.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-organizationNew",
    path: "/styleguide/organizationNew",
    component: () => import("/app/pages/styleguide/organizationNew.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-sidebar-dialog",
    path: "/styleguide/sidebar-dialog",
    component: () => import("/app/pages/styleguide/sidebar-dialog.vue").then(m => m.default || m)
  },
  {
    name: "templates-id",
    path: "/templates/:id()",
    component: () => import("/app/pages/templates/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "templates-id-edit",
    path: "edit",
    meta: editx7A3tlqm0GMeta || {},
    component: () => import("/app/pages/templates/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "templates-id-history",
    path: "history",
    meta: historyNT11UNLFgNMeta || {},
    component: () => import("/app/pages/templates/[id]/history.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "templates",
    path: "/templates",
    meta: index6xet4YTGVdMeta || {},
    redirect: index6xet4YTGVdMeta?.redirect,
    component: () => import("/app/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-new",
    path: "/templates/new",
    meta: newu8lSFnZjanMeta || {},
    component: () => import("/app/pages/templates/new.vue").then(m => m.default || m)
  },
  {
    name: "workflow-poc",
    path: "/workflow-poc",
    component: () => import("/app/pages/workflow-poc.vue").then(m => m.default || m)
  }
]