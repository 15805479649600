import { nb } from 'date-fns/locale';
import { format, formatDistance, parse, parseISO } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { DateFormat, TimeFormat } from '~/types/axos-api';

export function $formatTime(
	timeOnly?: string,
	outputFormat: string = 'HH:mm'
): string {
	if (!timeOnly) {
		return '';
	}

	try {
		const date = parse(timeOnly, 'HH:mm:ss', new Date());
		return format(date, outputFormat, { locale: nb });
	} catch (error) {
		console.error('Error parsing time:', error);
		return '';
	}
}
export function $formatDate(date: string | Date | null | undefined): string {
	if (!date) {
		return '';
	}

	const utcDate =
		typeof date === 'string' ? fromZonedTime(date, 'UTC') : date;

	if (isNaN(utcDate.getTime())) {
		console.error('Invalid date:', date);
		return '';
	}

	return format(utcDate, 'dd.MM.yyyy', { locale: nb });
}

export default function useDateUtils() {
	function timeAgo(
		date: Date,
		options?: { addSuffix?: boolean; includeSeconds?: boolean }
	) {
		const now = useNow();

		// Verify that the date is valid
		if (isNaN(date.getTime())) {
			return '';
		}

		return formatDistance(date, now.value, {
			locale: nb,
			addSuffix: options?.addSuffix ?? false,
			includeSeconds: options?.includeSeconds ?? false,
		});
	}

	function formatDate(dateFormat: DateFormat, date?: string | null) {
		if (!date) {
			return '';
		}

		const utcDate = fromZonedTime(date, 'UTC');

		if (isNaN(utcDate.getTime())) {
			console.error('Invalid date:', date);
			return '';
		}

		switch (dateFormat) {
			case DateFormat.Day:
				return format(utcDate, 'EEEE', { locale: nb });
			case DateFormat.DayMonth:
				return format(utcDate, 'dd.MM', { locale: nb });
			case DateFormat.DayMonthYear:
				return format(utcDate, 'dd.MM.yyyy', { locale: nb });
			case DateFormat.Month:
				return format(utcDate, 'MMMM', { locale: nb });
			case DateFormat.MonthYear:
				return format(utcDate, 'MMMM yyyy', { locale: nb });
			default:
				return '';
		}
	}

	function formatDateOnly(dateFormat: DateFormat, date?: string) {
		if (!date) {
			return '';
		}

		const dateWithTime = `${date}T00:00:00`;
		const utcDate = fromZonedTime(dateWithTime, 'UTC');

		if (isNaN(utcDate.getTime())) {
			console.error('Invalid date:', date);
			return '';
		}

		switch (dateFormat) {
			case DateFormat.Day:
				return format(utcDate, 'EEEE', { locale: nb });
			case DateFormat.DayMonth:
				return format(utcDate, 'dd.MM', { locale: nb });
			case DateFormat.DayMonthYear:
				return format(utcDate, 'dd.MM.yyyy', { locale: nb });
			case DateFormat.Month:
				return format(utcDate, 'MMMM', { locale: nb });
			case DateFormat.MonthYear:
				return format(utcDate, 'MMMM yyyy', { locale: nb });
			default:
				return '';
		}
	}

	function formatTime(timeFormat: TimeFormat, date: string) {
		const utcDate = fromZonedTime(date, 'UTC');

		if (isNaN(utcDate.getTime())) {
			console.error('Invalid time:', date);
			return '';
		}

		switch (timeFormat) {
			case TimeFormat.HourMinutes:
				return format(utcDate, 'HH:mm', { locale: nb });
			case TimeFormat.HourMinutesSeconds:
				return format(utcDate, 'HH:mm:ss', { locale: nb });
			default:
				return '';
		}
	}

	// Normalize a date to midnight, used in FormKit's datepicker for start dates and end dates
	function formatStartOfDay(date: Date | string): Date {
		const dateObj = typeof date === 'string' ? parseISO(date) : date;
		return startOfDay(dateObj);
	}

	return {
		timeAgo,
		formatDate,
		formatTime,
		formatDateOnly,
		formatStartOfDay,
	};
}
