import {
	AccessorFn,
	createColumnHelper,
	DeepKeys,
	DisplayColumnDef,
	IdentifiedColumnDef,
} from '@tanstack/vue-table';
import Badge from '~/components/Badge/Badge.vue';
import BadgeEmployee from '~/components/Badge/BadgeEmployee.vue';
import DOMPurify from 'dompurify';
import { defineComponent, h, ref } from 'vue';
import BadgeAccount from '~/components/Badge/BadgeAccount.vue';
import Button from '~/components/Button/Button.vue';
import Chip from '~/components/Chip/Chip.vue';
import Image from '~/components/Image/Image.vue';
import { DateFormat, TimeFormat } from '~/types/axos-api';
import { BadgeProps, BadgeSize, BadgeTheme } from '~/types/badge';
import { ButtonProps, ButtonTheme } from '~/types/button';
import { ColumnProperties } from './usePowerTableFeatures';

interface PowerTableColumnBaseOptions<TValue, TData> {
	id: string;
	label?: string;
	__type?: string;
	properties?: ColumnProperties;
	columnOptions?:
		| DisplayColumnDef<TData, TValue>
		| IdentifiedColumnDef<TData, TValue>;
}

type ColumnOptions<TValue, TData, CustomOptions> = PowerTableColumnBaseOptions<
	TValue,
	TData
> &
	CustomOptions;

function applyDefaults<T>(defaultOptions: Partial<T>, options: T): T {
	return {
		...defaultOptions,
		...options,
		columnOptions: {
			// @ts-ignore
			...defaultOptions.columnOptions,

			// @ts-ignore
			...options.columnOptions,
		},
	};
}

export default function <TData>() {
	const columnHelper = createColumnHelper<TData>();

	function createColumn<TValue extends any, CustomOptions = {}>(
		accessor: AccessorFn<TData, TValue> | DeepKeys<TData>,
		options: ColumnOptions<TValue, TData, CustomOptions>,
		defaultColumnOptions: Partial<
			ColumnOptions<TValue, TData, CustomOptions>
		> = {}
	) {
		const mergedOptions = applyDefaults(defaultColumnOptions, options);
		const { id, label, columnOptions, ...customOptions } = mergedOptions;

		return {
			...columnHelper.accessor(accessor, {
				id,
				header: label,
				size: '100%',
				...(columnOptions as any),
			}),
			...customOptions,
		};
	}

	// TEXT COLUMN
	interface TextColumnOptions {
		maxLength?: number;
	}
	type TextColumnOptionsType<TData> = ColumnOptions<
		string,
		TData,
		TextColumnOptions
	>;
	function textColumn(
		accessor: AccessorFn<TData, string | undefined> | DeepKeys<TData>,
		options: TextColumnOptionsType<TData>
	) {
		const defaultOptions: Partial<TextColumnOptionsType<TData>> = {
			__type: 'text',
			columnOptions: {
				// Add any default options specific to textColumn here
			},
		};
		return createColumn<string, TextColumnOptions>(
			accessor,
			options,
			defaultOptions
		);
	}

	// HTML COLUMN
	type HtmlColumnInput = string | undefined | null;
	interface HtmlColumnOptions {
		renderHtml?: boolean;
	}
	type HtmlColumnOptionsType<TData> = ColumnOptions<
		HtmlColumnInput,
		TData,
		HtmlColumnOptions
	>;
	function htmlColumn(
		accessor: AccessorFn<TData, HtmlColumnInput> | DeepKeys<TData>,
		options: HtmlColumnOptionsType<TData>
	) {
		const defaultOptions: Partial<HtmlColumnOptionsType<TData>> = {
			__type: 'html',
			columnOptions: {
				cell: ({ cell }) => {
					const value = cell.getValue();
					if (value) {
						const sanitizedHtml = DOMPurify.sanitize(value);
						return h('div', { innerHTML: sanitizedHtml });
					}
					return null;
				},
			},
		};
		return createColumn<string, HtmlColumnOptions>(
			accessor,
			options,
			defaultOptions
		);
	}

	// NUMBER COLUMN
	type NumberColumnInput = number | undefined | null;
	interface NumberColumnOptions {
		decimals?: number;
	}
	type NumberColumnOptionsType<TData> = ColumnOptions<
		NumberColumnInput,
		TData,
		NumberColumnOptions
	>;
	function numberColumn(
		accessor: AccessorFn<TData, NumberColumnInput> | DeepKeys<TData>,
		options: NumberColumnOptionsType<TData>
	) {
		const defaultOptions: Partial<NumberColumnOptionsType<TData>> = {
			__type: 'number',
			decimals: 2,
		};
		return createColumn<number, NumberColumnOptions>(
			accessor,
			options,
			defaultOptions
		);
	}

	// BOOLEAN COLUMN
	type BooleanColumnInput = boolean | undefined | null;
	interface BooleanColumnOptions {
		hideFalse?: boolean;
	}
	type BooleanColumnOptionsType<TData> = ColumnOptions<
		BooleanColumnInput,
		TData,
		BooleanColumnOptions
	>;
	function booleanColumn(
		accessor: AccessorFn<TData, BooleanColumnInput> | DeepKeys<TData>,
		options: BooleanColumnOptionsType<TData>
	) {
		const defaultOptions: Partial<BooleanColumnOptionsType<TData>> = {
			__type: 'boolean',
			columnOptions: {
				cell: ({ cell, returnType }) => {
					const value = cell.getValue();

					if (value === null || value === undefined) {
						return null;
					}

					if (options?.hideFalse && value === false) {
						return null;
					}

					if (returnType === 'string') {
						return value
							? $s('Core.Label.Yes')
							: $s('Core.Label.No');
					}

					return value
						? h(
								Badge,
								{
									theme: 'success',
									size: 'small',
								},
								$s('Core.Label.Yes')
						  )
						: h(
								Badge,
								{
									theme: 'info',
									size: 'small',
								},
								$s('Core.Label.No')
						  );
				},
			},
		};
		return createColumn<BooleanColumnInput, BooleanColumnOptions>(
			accessor,
			options,
			defaultOptions
		);
	}

	const { formatDate, formatTime } = useDateUtils();

	// DATE COLUMN
	type DateColumnInput = string | undefined | null;
	interface DateColumnOptions {}
	type DateColumnOptionsType<TData> = ColumnOptions<
		DateColumnInput,
		TData,
		DateColumnOptions
	>;

	function dateColumn(
		accessor: AccessorFn<TData, DateColumnInput> | DeepKeys<TData>,
		options: DateColumnOptionsType<TData>
	) {
		const defaultOptions: Partial<DateColumnOptionsType<TData>> = {
			__type: 'date',
			columnOptions: {
				cell: ({ cell }) => {
					const value = cell.getValue();
					const properties = cell.column.getProperties();

					if (value) {
						let formattedValue = '';

						if (properties.dateFormat !== null) {
							const dateFormatted = formatDate(
								properties.dateFormat ??
									DateFormat.DayMonthYear,
								value
							);
							formattedValue += dateFormatted;
						}

						if (properties.timeFormat !== null) {
							const timeFormatted = formatTime(
								properties.timeFormat ?? TimeFormat.HourMinutes,
								value
							);
							formattedValue += formattedValue
								? ` ${timeFormatted}`
								: timeFormatted;
						}

						return formattedValue || null;
					}

					return null;
				},
			},
		};
		return createColumn<DateColumnInput, DateColumnOptions>(
			accessor,
			options,
			defaultOptions
		);
	}

	// IMAGE COLUMN
	type ImageColumnInput = string | undefined | null;
	interface ImageColumnOptions {}
	type ImageColumnOptionsType<TData> = ColumnOptions<
		ImageColumnInput,
		TData,
		ImageColumnOptions
	>;
	function imageColumn(
		accessor: AccessorFn<TData, ImageColumnInput> | DeepKeys<TData>,
		options: ImageColumnOptionsType<TData>
	) {
		const defaultOptions: Partial<ImageColumnOptionsType<TData>> = {
			__type: 'image',
			properties: {
				align: 'center',
			},
			columnOptions: {
				size: 120,

				cell: ({ cell }) => {
					const value = cell.getValue();
					return value
						? h(Image, {
								src: value,
								height: 72,
								width: 72,
								class: 'aspect-square h-9 w-9 rounded-full object-cover object-center',
						  })
						: null;
				},
			},
		};
		return createColumn<ImageColumnInput, ImageColumnOptions>(
			accessor,
			options,
			defaultOptions
		);
	}

	// PROFILE PICTURE COLUMN
	type ProfilePictureColumnInput = string | undefined | null;
	interface ProfilePictureColumnOptions {}
	type ProfilePictureColumnOptionsType<TData> = ColumnOptions<
		ProfilePictureColumnInput,
		TData,
		ProfilePictureColumnOptions
	>;
	function profilePictureColumn(
		accessor:
			| AccessorFn<TData, ProfilePictureColumnInput>
			| DeepKeys<TData>,
		options: ProfilePictureColumnOptionsType<TData>
	) {
		const defaultOptions: Partial<ProfilePictureColumnOptionsType<TData>> =
			{
				__type: 'image',
				properties: {
					align: 'center',
				},
				columnOptions: {
					size: 120,

					enableSorting: false,

					cell: ({ cell }) => {
						const value = cell.getValue();
						if (value && value.startsWith('http')) {
							return h(Image, {
								src: value,
								height: 72,
								width: 72,
								class: 'aspect-square h-9 w-9 rounded-full object-cover object-center',
							});
						}

						if (!value) return null;
						return h(
							'div',
							{
								class: `flex h-9 w-9 items-center justify-center rounded-full ${
									value.length > 2 ? 'text-xs' : 'text-sm'
								} font-semibold ${$textToColor(value)}`,
							},
							value
						);
					},
				},
			};
		return createColumn<
			ProfilePictureColumnInput,
			ProfilePictureColumnOptions
		>(accessor, options, defaultOptions);
	}

	// LIST COLUMN
	type ListColumnInput = string[] | { label: string }[];
	interface ListColumnOptions {}
	type ListColumnOptionsType<TData> = ColumnOptions<
		ListColumnInput,
		TData,
		ListColumnOptions
	>;
	function listColumn(
		accessor: AccessorFn<TData, ListColumnInput> | DeepKeys<TData>,
		options: ListColumnOptionsType<TData>
	) {
		const defaultOptions: Partial<ListColumnOptionsType<TData>> = {
			__type: 'list',
			columnOptions: {
				cell: ({ cell, returnType }) => {
					const value = cell.getValue();

					const items: { label: string }[] = value.map((item) =>
						typeof item === 'string' ? { label: item } : item
					);

					if (returnType === 'string') {
						return items.map((item) => item.label).join(', ');
					}

					return h(
						'div',
						{ class: 'flex gap-x-1' },
						items.map((item) =>
							h(Chip, {
								value: item.label,
								key: item.label,
								size: 'small',
							})
						)
					);
				},
			},
		};
		return createColumn<ListColumnInput, ListColumnOptions>(
			accessor,
			options,
			defaultOptions
		);
	}

	// EMPLOYEE COLUMN
	type EmployeeColumnInput = number | undefined | null;
	interface EmployeeColumnOptions {}
	type EmployeeColumnOptionsType<TData> = ColumnOptions<
		EmployeeColumnInput,
		TData,
		EmployeeColumnOptions
	>;
	const { getEmployeeOptionLabel } = useDropdownEmployee();
	function employeeColumn(
		accessor: AccessorFn<TData, EmployeeColumnInput> | DeepKeys<TData>,
		options: EmployeeColumnOptionsType<TData>
	) {
		const defaultOptions: Partial<EmployeeColumnOptionsType<TData>> = {
			__type: 'employee',
			columnOptions: {
				cell: ({ cell, returnType }) => {
					const value = cell.getValue();

					if (returnType === 'string') {
						return value ? getEmployeeOptionLabel(value) : null;
					}

					return value
						? h(BadgeEmployee, {
								employeeId: value,
						  })
						: null;
				},
			},
		};
		return createColumn<EmployeeColumnInput, EmployeeColumnOptions>(
			accessor,
			options,
			defaultOptions
		);
	}

	// ACCOUNT COLUMN
	type AccountColumnInput = number | undefined | null;
	interface AccountColumnOptions {}
	type AccountColumnOptionsType<TData> = ColumnOptions<
		AccountColumnInput,
		TData,
		AccountColumnOptions
	>;

	const { getAccountOptionLabel } = useDropdownAccount();
	function accountColumn(
		accessor: AccessorFn<TData, AccountColumnInput> | DeepKeys<TData>,
		options: AccountColumnOptionsType<TData>
	) {
		const defaultOptions: Partial<AccountColumnOptionsType<TData>> = {
			__type: 'account',
			columnOptions: {
				cell: ({ cell, returnType }) => {
					const value = cell.getValue();

					if (returnType === 'string') {
						return value ? getAccountOptionLabel(value) : null;
					}

					return value
						? h(BadgeAccount, {
								accountId: value,
						  })
						: null;
				},
			},
		};

		return createColumn<AccountColumnInput, AccountColumnOptions>(
			accessor,
			options,
			defaultOptions
		);
	}

	// BUTTON COLUMN
	type ButtonColumnInput = string | undefined | null;
	interface ButtonColumnOptions {}
	type ButtonColumnOptionsType<TData> = ColumnOptions<
		ButtonColumnInput,
		TData,
		ButtonColumnOptions
	> &
		ButtonProps & {
			buttonLabel: string | ((row: TData) => string);
		};
	function buttonColumn(
		onClick: (row: TData) => void | Promise<void>,
		options: ButtonColumnOptionsType<TData>
	) {
		const defaultOptions: Partial<ButtonColumnOptionsType<TData>> = {
			__type: 'button',
			columnOptions: {
				cell: defineComponent({
					props: ['row', 'column', 'table'],
					setup(props) {
						const isLoading = ref(options?.loading ?? false);

						const handleClick = async (event: MouseEvent) => {
							event.stopPropagation();
							isLoading.value = true;
							try {
								await onClick(props.row.original);
							} finally {
								isLoading.value = false;
							}
						};

						return () => {
							const value = props.row.getValue(props.column.id);
							return value
								? h(
										Button,
										{
											...options,
											loading: isLoading.value,
											onClick: handleClick,
										},
										() =>
											typeof options.buttonLabel ===
											'function'
												? options.buttonLabel(
														props.row.original
												  )
												: options.buttonLabel
								  )
								: null;
						};
					},
				}),
			},
		};

		return createColumn<ButtonColumnInput, ButtonColumnOptions>(
			(row: any) => row.id,
			options,
			defaultOptions
		);
	}

	// BADGE COLUMN
	type BadgeColumnInput =
		| string
		| number
		| boolean
		| undefined
		| null
		| { [key: string]: any };

	interface BadgeItem {
		label: string;
		value: BadgeColumnInput | ((value: any) => boolean);
		theme: BadgeTheme;
		size?: BadgeSize;
	}

	interface BadgeColumnOptions extends BadgeProps {
		items: BadgeItem[];
	}

	type BadgeColumnOptionsType<TData> = ColumnOptions<
		BadgeColumnInput,
		TData,
		BadgeColumnOptions
	>;

	function badgeColumn(
		accessor: AccessorFn<TData, BadgeColumnInput> | DeepKeys<TData>,
		options: BadgeColumnOptionsType<TData>
	) {
		const defaultOptions: Partial<BadgeColumnOptionsType<TData>> = {
			__type: 'badge',
			columnOptions: {
				cell: ({ getValue, returnType }) => {
					const value = getValue();
					const item = options.items.find((item) =>
						typeof item.value === 'function'
							? item.value(value)
							: item.value === value
					);

					if (!item) return null;

					if (returnType === 'string') {
						return item.label;
					}

					return h(
						Badge,
						{
							theme: item.theme,
							size: item.size || 'small',
						},
						() => item.label
					);
				},
			},
		};
		return createColumn<BadgeColumnInput, BadgeColumnOptions>(
			accessor,
			options,
			defaultOptions
		);
	}

	// BUTTONS COLUMN
	type ButtonsColumnInput = string | undefined | null;

	interface ButtonConfig extends Omit<ButtonProps, 'onClick'> {
		label: string;
		onClick: (row: TData) => void | Promise<void>;
	}

	interface ButtonsColumnOptions {
		buttons: ButtonConfig[];
	}

	type ButtonsColumnOptionsType<TData> = ColumnOptions<
		ButtonsColumnInput,
		TData,
		ButtonsColumnOptions
	>;

	function buttonsColumn(
		accessor: AccessorFn<TData, ButtonsColumnInput> | DeepKeys<TData>,
		options: ButtonsColumnOptionsType<TData>
	) {
		const defaultOptions: Partial<ButtonsColumnOptionsType<TData>> = {
			__type: 'buttonsColumn',
			columnOptions: {
				cell: defineComponent({
					props: ['row', 'column', 'table'],
					setup(props) {
						const loadingStates = ref(
							options.buttons.map(() => false)
						);

						const handleClick = async (
							buttonConfig: ButtonConfig,
							index: number
						) => {
							loadingStates.value[index] = true;
							try {
								await buttonConfig.onClick(props.row.original);
							} finally {
								loadingStates.value[index] = false;
							}
						};

						return () => {
							const value = props.row.getValue(props.column.id);
							if (!value) return null;

							return h(
								'div',
								{ class: 'flex gap-2' },
								options.buttons.map((buttonConfig, index) =>
									h(
										Button,
										{
											...buttonConfig,
											key: buttonConfig.label,
											loading: loadingStates.value[index],
											onClick: (event: MouseEvent) => {
												event.stopPropagation();
												handleClick(
													buttonConfig,
													index
												);
											},
											theme:
												buttonConfig.theme ||
												('secondary' as ButtonTheme),
											size: buttonConfig.size || 'small',
										},
										() => buttonConfig.label
									)
								)
							);
						};
					},
				}),
			},
		};

		return createColumn<ButtonsColumnInput, ButtonsColumnOptions>(
			accessor,
			options,
			defaultOptions
		);
	}

	return {
		accessor: columnHelper.accessor,
		textColumn,
		htmlColumn,
		numberColumn,
		booleanColumn,
		dateColumn,
		imageColumn,
		listColumn,
		employeeColumn,
		accountColumn,
		profilePictureColumn,
		buttonColumn,
		badgeColumn,
		buttonsColumn,
	};
}
